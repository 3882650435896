<template>
  <a-row class="content-fix org">
    <a-button
      style="margin-top: 15px"
      type="primary"
      class="org back-btn"
      @click="$router.push({ name: 'faq' })"
    >
      <i class="t-transition icons icon-chevron-left" />
      Назад
    </a-button>

    <a-row class="content-title" type="flex">
      <h2 style="margin-bottom: 0">Вопросы</h2>
      <a-button
        style="margin-left: auto"
        type="primary"
        @click="$router.push({ name: 'faq-quesCreate' })"
      >
        <a-icon type="plus" />
        {{ $t("Add") }}
      </a-button>
    </a-row>

    <a-table
      :columns="columns"
      :data-source="form"
      :loading="loading"
      :pagination="false"
      :scroll="{ x: 2000, y: 600 }"
      :row-key="(record) => record.id"
    >
      <template slot="id" slot-scope="item">
        <span
          style="padding: 0"
          class="td-post-item"
          @click="
            $router.push({ name: 'faq-quesDetail', params: { id: item.id } })
          "
        >
          <b>{{ item.id }}</b>
        </span>
      </template>

      <template slot="order" slot-scope="item">
        <a-input-number
          v-model="item.order"
          style="width: 60px; margin-right: 10px"
          :min="1"
          @pressEnter="updatePosition(item, $event)"
        />
      </template>

      <template slot="question" slot-scope="item">
        <span
          style="padding: 0"
          class="td-post-item"
          @click="
            $router.push({ name: 'faq-quesDetail', params: { id: item.id } })
          "
        >
          {{ item.question || "Не заполнено" }}
        </span>
      </template>

      <template slot="answer" slot-scope="item">
        <div v-html="truncate(item.answer, 150, '...')" />
      </template>

      <template slot="status" slot-scope="item">
        <a-tag :color="item.is_active ? 'geekblue' : 'volcano'">
          {{ item.is_active ? $t("Published") : $t("NotPublished") }}
        </a-tag>
      </template>

      <template slot="created_at" slot-scope="item">
        {{ moment(item.created_at).format("LLL") }}
      </template>

      <template slot="updated_at" slot-scope="item">
        {{ moment(item.updated_at).format("LLL") }}
      </template>

      <template slot="operation" slot-scope="item">
        <a-button
          type="primary"
          class="edit-btn"
          @click="
            $router.push({ name: 'faq-quesDetail', params: { id: item.id } })
          "
        >
          <a-icon class="action-btns" type="edit" />
          <!--{{ $t("Edit") }}-->
        </a-button>
        <a-popconfirm
          cancel-text="Нет"
          ok-text="Да"
          title="Вы действительно хотите удалить?"
          @confirm="removeItem(item.id)"
        >
          <!-- <a-icon class="action-btns" style="margin: 0 10px" type="delete" />-->
          <a-button type="danger">
            <a-icon class="action-btns" type="delete" />
            <!--{{ $t("Delete") }}-->
          </a-button>
        </a-popconfirm>
      </template>
    </a-table>

    <a-row class="mt-1" style="padding-bottom: 15px">
      <a-pagination
        :key="limit"
        :current="page"
        :default-page-size="limit"
        :total="count || 0"
        @change="setPage"
      />
    </a-row>
  </a-row>
</template>

<script>
export default {
  data() {
    return {
      form: [],
      loading: false,
      columns: [
        {
          title: "ID",
          key: "id",
          width: 90,
          fixed: "left",
          scopedSlots: { customRender: "id" }
        },
        {
          title: this.$t("Order"),
          key: "order",
          width: 90,
          fixed: "left",
          scopedSlots: { customRender: "order" }
        },
        {
          title: this.$t("Question"),
          key: "question",
          width: "60%",
          scopedSlots: { customRender: "question" }
        },
        {
          title: this.$t("Answer"),
          key: "answer",
          width: "60%",
          scopedSlots: { customRender: "answer" }
        },
        {
          title: this.$t("Status"),
          key: "status",
          width: "20%",
          scopedSlots: { customRender: "status" }
        },
        {
          title: this.$t("TableCreatedDate"),
          key: "created_datetime",
          width: "30%",
          scopedSlots: { customRender: "created_at" }
        },
        {
          title: this.$t("TableLastUpdatedDate"),
          key: "updated_datetime",
          width: "30%",
          scopedSlots: { customRender: "updated_at" }
        },
        {
          title: this.$t("TableAction"),
          key: "operation",
          fixed: "right",
          scopedSlots: { customRender: "operation" }
        }
      ],
      page: 1,
      limit: 10,
      offset: 0,
      count: 0
    }
  },
  watch: {
    $route: {
      handler: "fetchData",
      immediate: true
    }
  },
  methods: {
    setPage(pageNumber) {
      this.$router.push({ query: { ...this.$route.query, page: pageNumber } })
    },
    truncate(text, length, clamp) {
      return this.$options.filters.truncate(text, length, clamp)
    },
    async fetchData() {
      this.loading = true
      this.offset = +this.$route.query.page || 1
      this.page = this.offset
      this.offset = (this.offset - 1) * this.limit
      try {
        const form = await this.$store.dispatch("faq/fetch", {
          theme: this.$route.params.theme_id,
          limit: this.limit,
          offset: this.offset
        })
        this.form = form.results
        this.count = form.count
        this.loading = false
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },
    removeItem(id) {
      try {
        this.$store.dispatch("faq/removeQuesAction", id)
      } catch (error) {
        console.log(error)
      }
    },
    updatePosition(node, event) {
      this.loading = true
      this.$api
        .patch(`/admin/faq/${node.id}/update/`, {
          order: event.target.value
        })
        .then(() => {
          this.loading = false
          this.$message.success("Позиция обновлена")
          this.fetchData()
        })
        .catch((err) => {
          this.loading = false
          this.$message.error(err.message)
        })
    }
  }
}
</script>

<style>
.ques .icon-chevron-left {
  width: 12px;
  height: 12px;
}
.ques .back-btn {
  display: flex;
  align-items: center;
}
</style>
